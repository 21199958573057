import React from 'react';
import logo from './logo.svg';
import './App.css';
import Button from '@mui/material/Button';
import AudioPlayer from './components/AudioPlayer';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to <code>benmangold.com</code>
        </p>
        {/* <AudioPlayer /> */}
        <Button variant="contained">Hello world</Button>
      </header>
    </div>
  );
}

export default App;
